import { generateCardTooltip, generateCrossingTooltip } from './helperFunctions';

export function Tooltip({props}) {
  const content = props 
    ? props.hasOwnProperty('enhanced') ? generateCardTooltip(props) : generateCrossingTooltip(props) 
    : null;

  return (
    <div id='tooltip' style={{display: props === null ? 'none' : 'block'}}>
        {content}
    </div>
  )
}