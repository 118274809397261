import { useState } from "react";
import App from "./App";
import LogIn from "./LogIn";
import { checkLogIn } from "./helperFunctions";


const Wall = () => {
  const [logIn, setLogIn] = useState(false)

  return checkLogIn(logIn) ? <App /> : <LogIn setLogIn={setLogIn} />
}

export default Wall;