import { allOrthogCrossings, validOrthogMoves, calculateDefenseValue, cardPlacementCells, cardPlacementCrossings, beginCardStage, beginContestStage, evaluateContest, evaluateSunContest, crossingCount, controlledCrossings } from './helperFunctions';
import { INVALID_MOVE } from 'boardgame.io/core';

const unShuffledCards = require('./SoothDeck.json')

export const SpidersGame = {
  setup: ({random}) => 
    ({crossings: Array(17).fill({
      outer: null,
      inner: null,
      player0: {},
      player1: {},
    }),
    cells: Array(10).fill(null),
    deck: random.Shuffle(unShuffledCards),
    discardPile: [],
    player0: {},
    player1: {},
    validMoves: [],
    antMovesRemaining: 0,
    drawnCard: null,
    playHistory: [],
    currentStage: null,
    contestVal: null,
    simpleMovement: false
  }),

  turn: {
    onBegin: ({G, ctx}) => {
      if (G.simpleMovement) {
        const orthogMoves = ctx.currentPlayer === '0' ? validOrthogMoves(G.player0.position, G.player1.position) : validOrthogMoves(G.player1.position, G.player0.position)
        return {...G, validMoves: orthogMoves, currentStage: 'move'}
      } else if (ctx.currentPlayer === '0') {
        const moveCount = crossingCount(G.crossings, ctx.currentPlayer) + 1
        const orthogMoves = validOrthogMoves(G.player0.position, G.player1.position)
        return {...G, validMoves: orthogMoves, antMovesRemaining: moveCount, currentStage: 'move'}
      } else if (ctx.currentPlayer === '1') {
        const web = controlledCrossings(G.crossings, ctx.currentPlayer)
        const onWeb = web.includes(G.player1.position)
        const adjacentCrossings = allOrthogCrossings(G.player1.position)
        const allMoves = []
        const webMoves = []

        const addToAllMoves = (i) => {
          if (!allMoves.includes(i) && i !== G.player0.position) {
            allMoves.push(i)
          }
        }

        //recursive function for adding spaces to web moves
        const addToWebMoves = (i) => {
          if (!webMoves.includes(i)) {
            webMoves.push(i)

            for (const crossing of allOrthogCrossings(i)) {
              if (web.includes(crossing)) {
                addToAllMoves(crossing)
                addToWebMoves(crossing)
              } else if (onWeb) {
                addToAllMoves(crossing)
              }
            }
          }
        }

        //initiates process by evaluating all adjacent crossings
        for (const i of adjacentCrossings) {
          addToAllMoves(i)

          if (web.includes(i)) {
            addToWebMoves(i)
          }
        }

        return {...G, validMoves: allMoves, currentStage: 'move'}
      }
    },
    stages: {
      contestOption: {
        moves: {
          makeContest: ({ G, events, playerID }) => {
            G.currentStage = 'contest';
            G.playHistory.push({type: 'contestDeclaration', player: playerID, event: {crossing: G[`player${playerID}`].position}, number: G.playHistory.length})
            events.setStage('contest');
          },
          skipStage: ({ G, events, playerID }) => {
            G.playHistory.push({type: 'pass', player: playerID, event: 'contest', number: G.playHistory.length});
            events.endTurn();
          }
        }
      },
      contest: {
        moves: {
          progressContest: ({ G, events, playerID, random }, die, sorcery, sun) => {
            const contestLog = []
            die > 0 && contestLog.push(`${die} from die`)
            if (sorcery > 0) {
              G[`player${playerID}`].sorcery = G[`player${playerID}`].sorcery - sorcery
              contestLog.push(`${sorcery} from sorcery`)
            }
            if (sun > 0) {
              G.cells[9] = []
              contestLog.push(`${sun} from the Invisible Sun`)
            }
            G.contestVal = [parseInt(die + sorcery + sun), contestLog]
            beginCardStage(G, events, playerID, random);
          },
          contestSun: ({ G, events, playerID }, die, sorcery, sun) => {
            const contestLog = []
            die > 0 && contestLog.push(`${die} from die`)
            if (sorcery > 0) {
              G[`player${playerID}`].sorcery = G[`player${playerID}`].sorcery - sorcery
              contestLog.push(`${sorcery} from sorcery`)
            }
            if (sun > 0) {
              G.discardPile.push(...G.cells[9])
              G.cells[9] = []
              contestLog.push(`${sun} from the Invisible Sun`)
            }
            G.contestVal = [parseInt(die + sorcery + sun), contestLog];
            evaluateSunContest(G, playerID);
            events.endTurn();
          },
          aggregateSubmit: ({ G, playerID }, aggregates) => {
            G[`player${playerID}`].aggregates = aggregates;
          }
        }
      },
      card: {
        moves: {
          placeCard: ({ G, events, playerID, random }, id) => {
            const cardToPlace = G.drawnCard

            const affectedCells = cardPlacementCells[id];
            G.discardPile.push(G.cells[affectedCells[0]]);
            G.cells[affectedCells[0]] = G.cells[affectedCells[1]];
            G.cells[affectedCells[1]] = G.cells[affectedCells[2]];
            G.cells[affectedCells[2]] = cardToPlace;
            const crossingsToUpdate = cardPlacementCrossings[id];
            G.playHistory.push({type: 'card', player: playerID, event: {cell: id, card: cardToPlace}, number: G.playHistory.length})

            crossingsToUpdate.forEach(i => {
              const newCrossing = calculateDefenseValue(G, i);
              G.crossings[i] = newCrossing;
            })
            


            if (cardToPlace.type === 'Adept') {
              beginCardStage(G, events, playerID, random);
            } else {
              evaluateContest(G, playerID);
              G.drawnCard = null;
              events.endTurn();
            }
          },
        }
      }
    }
  },

  moves: {
    crossingClick: ({ G, events, playerID }, id) => {
      if (G.simpleMovement) {
        if (G.validMoves.includes(id)) {
          G[`player${playerID}`].position = id;
          G.playHistory.push({type: 'move', player:playerID, event: {crossing: id}, number: G.playHistory.length});

          beginContestStage(G, events);
        } else {
          return INVALID_MOVE;
        }
      } else if (playerID === '0' && G.validMoves.includes(id)) {
        G.player0.position = id;
        G.playHistory.push({type: 'move', player:'0', event: {crossing: id}, number: G.playHistory.length})
        G.antMovesRemaining = G.antMovesRemaining - 1;

        if (G.antMovesRemaining === 0) {
          beginContestStage(G, events);
        } else {
          G.validMoves = validOrthogMoves(G.player0.position, G.player1.position);
        }
      } else if (playerID === '1' && G.validMoves.includes(id)) {
        G.player1.position = id;
        G.playHistory.push({type: 'move', player:'1', event: {crossing: id}, number: G.playHistory.length});

        beginContestStage(G, events);
      } else {
        return INVALID_MOVE;
      }
    },
    skipStage: ({ G, events, playerID }) => {
      G.playHistory.push({type: 'pass', player: playerID, event: 'movement', number: G.playHistory.length});
      beginContestStage(G, events);
    }
  },

  phases: {
    setup: {
      moves: {
        startGame: ({ G, events }, setupData, order ) => {
          let ant;
          let spider;
          let first;
          let second;
          if (setupData.player1.token === 'ant') {
            ant = 'player1';
            spider = 'player2';
            if (order[0] === 1) {
              first = '0'
              second = '1'
            } else {
              first = '1'
              second = '0'
            }
          } else {
            spider = 'player1';
            ant = 'player2';
            if (order[0] === 1) {
              first = '1'
              second = '0'
            } else {
              first = '0'
              second = '1'
            }
          }

          G.player0 = {
            suit: setupData[ant].suit, 
            weaver: setupData[ant].weaver,
            aggregates: setupData[ant].aggregates.slice(0, (parseInt(setupData[ant].weaver) + 1)).map((agg, i) => (
              {name: agg, used: false, enhanced: i>=3}
            )),
            position: parseInt(setupData[ant].position), 
            sorcery: parseInt(setupData.sorcery)
          }

          G.player1 = {
            suit: setupData[spider].suit, 
            weaver: setupData[spider].weaver,
            aggregates: setupData[spider].aggregates.slice(0, (parseInt(setupData[spider].weaver) + 1)).map((agg, i) => (
              {name: agg, used: false, enhanced: i>=3}
            )),
            position: parseInt(setupData[spider].position), 
            sorcery: parseInt(setupData.sorcery)
          }
          
          G.playHistory.push({type: 'text', player:'0', event: `chose ${G.player0.suit}.`, number: 0});
          G.playHistory.push({type: 'text', player:'1', event: `chose ${G.player1.suit}.`, number: 1});
          G.playHistory.push({type: 'text', player: first, event: `goes first!`, number: 2});
      
      
          G.cells[9] = []
      
          for (let i=0; i < 9; i++) {
            let drawnCard
      
            //keep drawing until non-invisible card drawn
            let validCard = false
            do {
              drawnCard = G.deck.pop();
              if (drawnCard.enhanced === 'Invisible') {
                G.playHistory.push({type: 'card-set', event: {card: drawnCard, cell: 'Invisible'}, number: G.playHistory.length});
                G.cells[9].push(drawnCard);
              } else {
                validCard = true
              }
            } while (validCard === false)
      
            //if companion, copy previous card
            if (drawnCard.type === 'Companion' && i !== 0) {
              for (let i = G.playHistory.length - 1; i >= 0; i--) {
                if (G.playHistory[i].type === 'card-set') {
                  const cardToCopy = G.playHistory[i].event.card;
                  drawnCard = {
                    name: `${drawnCard.name} (copy of ${cardToCopy.name})`,
                    enhanced: cardToCopy.enhanced,
                    diminished: cardToCopy.diminished,
                    suit: cardToCopy.suit,
                    number: drawnCard.number,
                    type: cardToCopy.type,
                    img: drawnCard.img
                  }
                  break;
                }
              }
            }
      
            G.playHistory.push({type: 'card-set', event: {card: drawnCard, cell: i}, number: G.playHistory.length});
            G.cells[i] = drawnCard;
          }
      
          for (let i = 0; i <= 16; i++) {
            const newCrossing = calculateDefenseValue(G, i);
            G.crossings[i] = newCrossing
          }

          G.simpleMovement = setupData.simpleMovement

          events.endTurn({next: second})
          events.endPhase();
        },
      },
      start: true,
    }
  },

  endIf: ({G, ctx}) => {
    if (crossingCount(G.crossings, ctx.currentPlayer) >= 9) {
      return { winner: ctx.currentPlayer }
    }
  }
};