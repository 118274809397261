const allOrthogCrossings = (pos) => {
    const orthogCrossings = []
    for (let i = 0; i <= 15; i++) {
        if (i === pos) {continue}
        else if (i === pos + 4) {orthogCrossings.push(i)}
        else if (i === pos - 4) {orthogCrossings.push(i)}
        else if (i === pos + 1 && ![3,7,11].includes(pos)) {orthogCrossings.push(i)}
        else if (i === pos - 1 && ![4,8,12].includes(pos)) {orthogCrossings.push(i)}
        else {continue}
    }
    return orthogCrossings
}

const validOrthogMoves = (playerPos, oppPos) => {
    return allOrthogCrossings(playerPos).filter(i => i !== oppPos)
}

const validDiagMoves = (playerPos, oppPos) => {
    const validMoves = []
    for (let i = 0; i <= 15; i++) {
        if (i === playerPos || i === oppPos) {continue}
        else if (i === playerPos + 5 && ![3,7,11].includes(playerPos)) {validMoves.push(i)}
        else if (i === playerPos + 3 && ![0,4,8,12].includes(playerPos)) {validMoves.push(i)} 
        else if (i === playerPos - 5 && ![8,12].includes(playerPos)) {validMoves.push(i)}
        else if (i === playerPos - 3 && ![3,7,11,15].includes(playerPos)) {validMoves.push(i)}
        else {continue}
    }
    return validMoves
}

const calculateEnhOrDim = (cards,colors,suits) => {
    const common = {}
    const player0 = {}
    const player1 = {}
    const player0valueLog = []
    const player1valueLog = []
    cards.forEach((card, index) => {
        const cardName = `sooth${index + 1}`
        if (card.type === '') {
            if (colors.includes(card.enhanced)) {
                common[cardName] = 1
                player0valueLog.push(`1 from ${card.name}`)
                player1valueLog.push(`1 from ${card.name}`)
            } else if (colors.includes(card.diminished)) {
                common[cardName] = -1
                player0valueLog.push(`-1 from ${card.name}`)
                player1valueLog.push(`-1 from ${card.name}`)
            } else {
                common[cardName] = 0
            }
        } else if (card.type === 'Sovereign') {
            if (card.suit === suits[0]) {
                player1[cardName] = 2
                player1valueLog.push(`2 from ${card.name}`)
            } else {
                player1[cardName] = 1
                player1valueLog.push(`1 from ${card.name}`)
            } if (card.suit === suits[1]) {
                player0[cardName] = 2
                player0valueLog.push(`2 from ${card.name}`)
            } else {
                player0[cardName] = 1
                player0valueLog.push(`1 from ${card.name}`)
            }
        } else if (card.type === 'Nemesis') {
            if (card.suit === suits[0]) {
                player1[cardName] = -2
                player1valueLog.push(`-2 from ${card.name}`)
            } else {
                player1[cardName] = -1
                player1valueLog.push(`-1 from ${card.name}`)
            } if (card.suit === suits[1]) {
                player0[cardName] = -2
                player0valueLog.push(`-2 from ${card.name}`)
            } else {
                player0[cardName] = -1
                player0valueLog.push(`-1 from ${card.name}`)
            }
        } else if (card.type === 'Defender') {
            if (card.suit === suits[0]) {
                player1[cardName] = 2
                player1valueLog.push(`2 from ${card.name}`)
            } else {
                player1[cardName] = 0
            } if (card.suit === suits[1]) {
                player0[cardName] = 2
                player0valueLog.push(`2 from ${card.name}`)
            } else {
                player0[cardName] = 0
            }
        } else if (card.type === 'Apprentice') {
            if (card.suit === suits[0]) {
                player1[cardName] = -1
                player1valueLog.push(`-1 from ${card.name}`)
            } else {
                player1[cardName] = 0
            } if (card.suit === suits[1]) {
                player0[cardName] = -1
                player0valueLog.push(`-1 from ${card.name}`)
            } else {
                player0[cardName] = 0
            }
        } else if (card.type === 'Companion') {
            common[cardName] = 0
        } else if (card.type === 'Adept') {
            common[cardName] = 0
        }        
    })
    return [{...common, ...player0}, player0valueLog, {...common, ...player1}, player1valueLog];
}

const calculateDefenseValue = (G, id) => {
    const crossing = G.crossings[id];

    let player0 = {};
    let player1 = {};
    let player0valueLog = [];
    let player1valueLog = [];

    // calculate current influence
    if (id === 0) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[0]],[],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 2
            player0valueLog.push('2 from initial difficulty')
            player1.initial = 2
            player1valueLog.push('2 from initial difficulty')
        }
    } else if (id === 1) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[0],G.cells[1]],["Silver"],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 4
            player0valueLog.push('4 from initial difficulty')
            player1.initial = 4
            player1valueLog.push('4 from initial difficulty')
        }
    } else if (id === 2) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[1],G.cells[2]],["Blue"],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 4
            player0valueLog.push('4 from initial difficulty')
            player1.initial = 4
            player1valueLog.push('4 from initial difficulty')
        }
    } else if (id === 3) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[2]],[],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 2
            player0valueLog.push('2 from initial difficulty')
            player1.initial = 2
            player1valueLog.push('2 from initial difficulty')
        }
    } else if (id === 4) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[0],G.cells[3]],["Indigo"],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 4
            player0valueLog.push('4 from initial difficulty')
            player1.initial = 4
            player1valueLog.push('4 from initial difficulty')
        }
    } else if (id === 5) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[0],G.cells[1],G.cells[3],G.cells[4]],["Silver","Indigo"],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 6
            player0valueLog.push('6 from initial difficulty')
            player1.initial = 6
            player1valueLog.push('6 from initial difficulty')
        }
    } else if (id === 6) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[1],G.cells[2],G.cells[4],G.cells[5]],["Blue","Grey"],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 6
            player0valueLog.push('6 from initial difficulty')
            player1.initial = 6
            player1valueLog.push('6 from initial difficulty')
        }
    } else if (id === 7) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[2],G.cells[5]],["Grey"],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 4
            player0valueLog.push('4 from initial difficulty')
            player1.initial = 4
            player1valueLog.push('4 from initial difficulty')
        }
    } else if (id === 8) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[3],G.cells[6]],["Green"],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 4
            player0valueLog.push('4 from initial difficulty')
            player1.initial = 4
            player1valueLog.push('4 from initial difficulty')
        }
    } else if (id === 9) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[3],G.cells[4],G.cells[6],G.cells[7]],["Green","Gold"],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 6
            player0valueLog.push('6 from initial difficulty')
            player1.initial = 6
            player1valueLog.push('6 from initial difficulty')
        }
    } else if (id === 10) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[4],G.cells[5],G.cells[7],G.cells[8]],["Pale","Red"],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 6
            player0valueLog.push('6 from initial difficulty')
            player1.initial = 6
            player1valueLog.push('6 from initial difficulty')
        }
    } else if (id === 11) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[5],G.cells[8]],["Pale"],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 4
            player0valueLog.push('4 from initial difficulty')
            player1.initial = 4
            player1valueLog.push('4 from initial difficulty')
        }
    } else if (id === 12) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[6]],[],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 2
            player0valueLog.push('2 from initial difficulty')
            player1.initial = 2
            player1valueLog.push('2 from initial difficulty')
        }
    } else if (id === 13) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[6],G.cells[7]],["Gold"],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 4
            player0valueLog.push('4 from initial difficulty')
            player1.initial = 4
            player1valueLog.push('4 from initial difficulty')
        }
    } else if (id === 14) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[7],G.cells[8]],["Red"],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 4
            player0valueLog.push('4 from initial difficulty')
            player1.initial = 4
            player1valueLog.push('4 from initial difficulty')
        }
    } else if (id === 15) {
        [player0, player0valueLog, player1, player1valueLog] = calculateEnhOrDim([G.cells[8]],[],[G.player0.suit,G.player1.suit])
        if (crossing.inner === null && crossing.outer === null) {
            player0.initial = 2
            player0valueLog.push('2 from initial difficulty')
            player1.initial = 2
            player1valueLog.push('2 from initial difficulty')
        }
    } else if (id === 16) {
        //Invisible Sun returns here (all of its calculations are within this loop)
        if (crossing.outer === '0') {
            player1.control = 12
            player1.defense = 12
            player1valueLog.push('12 from control')
        } else {
            player1.control = 0
            player1.defense = 0
        }
        if (crossing.outer === '1') {
            player0.control = 12
            player0.defense = 12
            player0valueLog.push('12 from control')
        } else {
            player0.control = 0
            player0.defense = 0
        }
        return {outer: crossing.outer, player0: {...player0, valueLog: player0valueLog}, player1: {...player1, valueLog: player1valueLog}};
    }

    // adjust for control
    if (crossing.inner === '0') {
        player1.inner = 6
        player1valueLog.push('6 from inner')
    } else {
        player1.inner = 0
    }
    if (crossing.outer === '0') {
        player1.outer = 6
        player1valueLog.push('6 from outer')
    } else {
        player1.outer = 0
    }
    if (crossing.inner === '1') {
        player0.inner = 6
        player0valueLog.push('6 from inner')
    } else {
        player0.inner = 0
    }
    if (crossing.outer === '1') {
        player0.outer = 6
        player0valueLog.push('6 from outer')
    } else {
        player0.outer = 0
    }

    player0.defense = Object.values(player0).reduce((a, b) => a + b, 0);
    player1.defense = Object.values(player1).reduce((a, b) => a + b, 0);

    const newCrossing = {inner: crossing.inner, outer: crossing.outer, player0: {...player0, valueLog: player0valueLog}, player1: {...player1, valueLog: player1valueLog}};

    return newCrossing;
}

const generateCrossingTooltip = (defenseValues) => {
    const arr = [];
    if (defenseValues?.initial !== undefined) {arr.push(<p key='initial'>Initial difficulty: {defenseValues.initial}</p>)}
    if (defenseValues?.inner !== undefined) {arr.push(<p key='inner'>Inner: {defenseValues.inner}</p>)};
    if (defenseValues?.outer !== undefined) {arr.push(<p key='outer'>Outer: {defenseValues.outer}</p>)};
    if (defenseValues?.control !== undefined) {arr.push(<p key='control'>Control: {defenseValues.control}</p>)};
    if (defenseValues?.sooth1 !== undefined) {arr.push(<p key='sooth1'>Sooth 1: {defenseValues.sooth1}</p>)};
    if (defenseValues?.sooth2 !== undefined) {arr.push(<p key='sooth2'>Sooth 2: {defenseValues.sooth2}</p>)};
    if (defenseValues?.sooth3 !== undefined) {arr.push(<p key='sooth3'>Sooth 3: {defenseValues.sooth3}</p>)};
    if (defenseValues?.sooth4 !== undefined) {arr.push(<p key='sooth4'>Sooth 4: {defenseValues.sooth4}</p>)};
    return arr;
}

const generateCardTooltip = (card) => {
    const arr = [];
    arr.push(<p key='name'>Name: {card.name}</p>);
    if (card.enhanced !== '') {arr.push(<p key='enhanced'>Enhanced: {card.enhanced}</p>)};
    if (card.diminished !== '') {arr.push(<p key='diminished'>Diminished: {card.diminished}</p>)};
    if (card.type !== '') {arr.push(<p key='type'>Type: {card.type}</p>)};
    arr.push(<p key='suit'>Suit: {card.suit}</p>);
    arr.push(<p key='number'>Number: {card.number}</p>);
    return arr;
}

const cellBorderStyle = (id) => {
    if (id === 0) {
        return {
            borderRight:'6px solid #C0C0C0',
            borderBottom: '6px solid #4B0082'
        }
    } if (id === 1) {
        return {
            borderLeft:'6px solid #C0C0C0',
            borderRight: '6px solid #004a8d'
        }
    } if (id === 2) {
        return {
            borderLeft: '6px solid #004a8d',
            borderBottom: '6px solid #626262'
        }
    } if (id === 3) {
        return {
            borderTop:'6px solid #4B0082',
            borderBottom: '6px solid green'
        }
    } if (id === 5) {
        return {
            borderTop:'6px solid #626262',
            borderBottom:'6px solid #ffe9f0'
        }
    } if (id === 6) {
        return {
            borderTop:'6px solid green',
            borderRight:'6px solid #dba500'
        }
    } if (id === 7) {
        return {
            borderLeft:'6px solid #dba500',
            borderRight:'6px solid #990000'
        }
    } if (id === 8) {
        return {
            borderTop:'6px solid #ffe9f0',
            borderLeft:'6px solid #990000'
        }
    }
}

const printCellName = {
    0: 'A3',
    1: 'B3',
    2: 'C3',
    3: 'A2',
    4: 'B2',
    5: 'C2',
    6: 'A1',
    7: 'B1',
    8: 'C1',
    Invisible: 'the Invisible Sun'
}

const crossingName = {
    0: 'A4',
    1: 'B4',
    2: 'C4',
    3: 'D4',
    4: 'A3',
    5: 'B3',
    6: 'C3',
    7: 'D3',
    8: 'A2',
    9: 'B2',
    10: 'C2',
    11: 'D2',
    12: 'A1',
    13: 'B1',
    14: 'C1',
    15: 'D1'
}

const rowColName = {
    0: 'col A',
    1: 'col B',
    2: 'col C',
    3: 'row 3',
    4: 'row 3',
    5: 'row 2',
    6: 'row 2',
    7: 'row 1',
    8: 'row 1',
    9: 'col A',
    10: 'col B',
    11: 'col C',
    Invisible: 'the Invisible Sun'
}

const generatePlayLogText = (event) => {
    if (event.type === 'text') {
        return event.event
    } else if (event.type === 'move') {
        return `moved to ${crossingName[event.event.crossing]}`
    } else if (event.type === 'card') {
        return `played ${event.event.card.name} to ${rowColName[event.event.cell]}`
    } else if (event.type === 'card-set') {
        return `${event.event.card.name} was set at ${printCellName[event.event.cell]}`
    } else if (event.type === 'pass') {
        return `passed their ${event.event} phase`
    } else if (event.type === 'contestDeclaration') {
        return `is contesting ${crossingName[event.event.crossing]}`
    } else if (event.type === 'contestResult') {
        const contestBreakdown = event.event.scores[1].length === 0 ? '' : ` (${event.event.scores[1].join(', ')})`
        const crossingBreakdown = event.event.scores[3].length === 0 ? '' : ` (${event.event.scores[3].join(', ')})`

        if (event.event.result === 'success') {
            return `succeeded ${event.event.scores[0]}${contestBreakdown} to ${event.event.scores[2]}${crossingBreakdown}. They now control ${crossingName[event.event.crossing]} ${event.event.layer}`
        } else if (event.event.result === 'overkill') {
            return `succeeded with overkill ${event.event.scores[0]}${contestBreakdown} to ${event.event.scores[2]}${crossingBreakdown}. They now control ${crossingName[event.event.crossing]}`
        } else {
            return `failed their contest ${event.event.scores[0]}${contestBreakdown} to ${event.event.scores[2]}${crossingBreakdown}`
        }
    } else if (event.type === 'sunContestResult') {
        const contestBreakdown = event.event.scores[1].length === 0 ? '' : ` (${event.event.scores[1].join(', ')})`
        const crossingBreakdown = event.event.scores[3].length === 0 ? '' : ` (${event.event.scores[3].join(', ')})`

        if (event.event.success) {
            return `contested the Invisible Sun and succeeded ${event.event.scores[0]}${contestBreakdown} to ${event.event.scores[2]}${crossingBreakdown}!`
        } else {
            return `contested the Invisible Sun and failed ${event.event.scores[0]}${contestBreakdown} to ${event.event.scores[2]}${crossingBreakdown}`
        }
    }
}

const crossingHighlight = ({ctx, G, id}) => {
    if (G.validMoves.includes(id)) {
        return ctx.currentPlayer === '0' ? 'highlight-ant' : 'highlight-spider'
    } else {
        return 'highlight-none'
    }
  }

const crossingColorInner = ({G, id}) => {
    if (G.crossings[id].inner === '0') {
        return {backgroundColor:'#fa6b61'}
    } else if (G.crossings[id].inner === '1') {
        return {backgroundColor:'#59b8ff'}
    } else {
        return {backgroundColor:'#d3d3d3'}
    }
}

const crossingColorOuter = ({G, id}) => {
    if (G.crossings[id].outer === '0') {
        return {backgroundColor:'#fa6b61'}
    } else if (G.crossings[id].outer === '1') {
        return {backgroundColor:'#59b8ff'}
    } else {
        return {backgroundColor:'#d3d3d3'}
    }
}

const skipButtonStyle = (id) => {
    if (id === '0') {
        return {color: '#fa6b61', borderColor: '#fa6b61'}
    } else {
        return {color: '#59b8ff', borderColor: '#59b8ff'}
    }
}

const actionButtonStyle = (id) => {
    if (id === '0') {
        return {backgroundColor:'#fa6b61'}
    } else {
        return {backgroundColor:'#59b8ff'}
    }
}

const cardPlacementCells = {
    0: [6,3,0],
    1: [7,4,1],
    2: [8,5,2],
    3: [2,1,0],
    4: [0,1,2],
    5: [5,4,3],
    6: [3,4,5],
    7: [8,7,6],
    8: [6,7,8],
    9: [0,3,6],
    10: [1,4,7],
    11: [2,5,8]
}

const cardPlacementCrossings = {
    0: [0,1,4,5,8,9,12,13],
    1: [1,2,5,6,9,10,13,14],
    2: [2,3,6,7,10,11,14,15],
    3: [0,1,2,3,4,5,6,7],
    4: [0,1,2,3,4,5,6,7],
    5: [4,5,6,7,8,9,10,11],
    6: [4,5,6,7,8,9,10,11],
    7: [8,9,10,11,12,13,14,15],
    8: [8,9,10,11,12,13,14,15],
    9: [0,1,4,5,8,9,12,13],
    10: [1,2,5,6,9,10,13,14],
    11: [2,3,6,7,10,11,14,15]
}

const beginContestStage = (G, events) => {
    G.currentStage = 'contestOption';
    G.validMoves = [];
    events.setStage('contestOption');
}

const beginCardStage = (G, events, playerID, random) => {
    G.currentStage = 'card';

    //check if deck is empty and shuffle discard pile into new deck if needed
    if (G.deck.length === 0) {
        G.deck = random.Shuffle(G.discardPile)
        G.discardPile = []
    }

    G.drawnCard = G.deck.pop();
    
    if (G.drawnCard.type === 'Companion') {
        for (let i = G.playHistory.length - 1; i >= 0; i--) {
          if (G.playHistory[i].type === 'card' || G.playHistory[i].type === 'card-set') {
            const cardToCopy = G.playHistory[i].event.card;
            const newCard = {
              name: `${G.drawnCard.name} (copy of ${cardToCopy.name})`,
              enhanced: cardToCopy.enhanced,
              diminished: cardToCopy.diminished,
              suit: cardToCopy.suit,
              number: G.drawnCard.number,
              type: cardToCopy.type,
              img: G.drawnCard.img
            }
            G.drawnCard = newCard;
            break;
          }
        }
      }

      if (G.drawnCard.enhanced === 'Invisible') {
        G.cells[9].push(G.drawnCard)
        G.playHistory.push({type: 'card', player: playerID, event: {cell: 'Invisible', card: G.drawnCard}, number: G.playHistory.length})
        evaluateContest(G, playerID);
        G.drawnCard = null;
        events.endTurn();
        return;
    } 
    
      events.setStage('card');
}

const evaluateContest = (G, playerID) => {
    const position = G[`player${playerID}`].position
    const crossing  = G.crossings[position]
    const layer = crossing.outer === playerID ? 'inner' : 'outer';

    if (layer === 'outer' && G.contestVal[0]-6 >= crossing[`player${playerID}`].defense) {
        crossing.outer = playerID
        crossing.inner = playerID
        G.crossings[position] = calculateDefenseValue(G, position)
        G.playHistory.push({type: 'contestResult', player: playerID, event: {result: 'overkill', scores: [G.contestVal[0], G.contestVal[1], crossing[`player${playerID}`].defense, crossing[`player${playerID}`].valueLog], crossing: position}, number: G.playHistory.length})
    } else if (G.contestVal[0] >= crossing[`player${playerID}`].defense) {
        crossing[layer] = playerID
        G.crossings[position] = calculateDefenseValue(G, position)
        G.playHistory.push({type: 'contestResult', player: playerID, event: {result: 'success', scores: [G.contestVal[0], G.contestVal[1], crossing[`player${playerID}`].defense, crossing[`player${playerID}`].valueLog], crossing: position, layer: layer}, number: G.playHistory.length})
    } else {
        G.playHistory.push({type: 'contestResult', player: playerID, event: {result: 'failure', scores: [G.contestVal[0], G.contestVal[1], crossing[`player${playerID}`].defense, crossing[`player${playerID}`].valueLog]}, number: G.playHistory.length})
    }
}

const evaluateSunContest = (G, playerID) => {
    const crossing = G.crossings[16]

    if (G.contestVal[0] >= crossing[`player${playerID}`].defense) {
        crossing.outer = playerID
        G.crossings[16] = calculateDefenseValue(G, 16)
        G.playHistory.push({type: 'sunContestResult', player: playerID, event: {success: true, scores: [G.contestVal[0], G.contestVal[1], crossing[`player${playerID}`].defense, crossing[`player${playerID}`].valueLog]}, number: G.playHistory.length})
    } else {
        G.playHistory.push({type: 'sunContestResult', player: playerID, event: {success: false, scores: [G.contestVal[0], G.contestVal[1], crossing[`player${playerID}`].defense, crossing[`player${playerID}`].valueLog]}, number: G.playHistory.length})
    }
}

const controlledCrossings = (crossings, player) => {
    const controlled = [];

    for (let i = 0; i < 16; i++) {
        crossings[i].inner === player && controlled.push(i)
    }

    crossings[16].outer === player && controlled.push(16)

    return controlled
}

const crossingCount = (crossings, player) => {
    return controlledCrossings(crossings, player).length
}

const checkLogIn = (input) => {
    return input === 'uncoupdedes'
}

export { 
    allOrthogCrossings,
    validOrthogMoves,
    validDiagMoves,
    calculateDefenseValue,
    cellBorderStyle,
    generateCrossingTooltip,
    generateCardTooltip,
    generatePlayLogText,
    crossingHighlight,
    crossingColorInner,
    crossingColorOuter,
    cardPlacementCells,
    cardPlacementCrossings,
    beginContestStage,
    beginCardStage,
    crossingName,
    evaluateContest,
    evaluateSunContest,
    skipButtonStyle,
    actionButtonStyle,
    controlledCrossings,
    crossingCount,
    checkLogIn
}