import antIcon from './assets/ant-icon.png';
import spiderIcon from './assets/spider-icon.png';
import { crossingColorInner, crossingColorOuter, crossingHighlight } from './helperFunctions';

export function Crossings({G, ctx, moves, setTooltipProps, crossingDisplay}) {
  const onClick = (id) => moves.crossingClick(id);
/*
  let cardsActive;
  if (G.drawnCard) {cardsActive = {opacity: 1}}
*/

  const crossingStyle = (id) => {
    const styleObj = {}

    if (G.drawnCard) {
      styleObj.opacity = 1

      const base = G.crossings[id][`player${ctx.currentPlayer}`].defense  
      const modified = crossingDisplay[id][`player${ctx.currentPlayer}`].defense  

      if (modified > base) {
        styleObj.color = '#008000'
      } else if (base > modified) {
        styleObj.color = '#990000'
      }
    }

    return styleObj
  }

  let crossingsBody = [];
  for (let i = 0; i < 4; i++) {
    let crossings = [];
    for (let j = 0; j < 4; j++) {
      const id = 4 * i + j;
      crossings.push(
        <div key={id} className='crossings-div'>
          <div className={'crossings-highlight ' + crossingHighlight({ctx, G, id})}>
            <button className='crossings-item crossings-outer' style={crossingColorOuter({G, id})} onClick={() => onClick(id)}
              onMouseEnter={() => setTooltipProps(G.crossings[id][`player${ctx.currentPlayer}`])} onMouseLeave={() => setTooltipProps(null)}>
              <div className='crossings-item crossings-inner' style={crossingColorInner({G, id})}>
                {G.player0.position === id && <img src={antIcon} alt='ant' className='player-token'/>}
                {G.player1.position === id && <img src={spiderIcon} alt='spider' className='player-token'/>}
              </div>
              <div className='defense-value' style={crossingStyle(id)}><p>{crossingDisplay[id][`player${ctx.currentPlayer}`].defense}</p></div>
            </button>
          </div>
        </div>
      );
    }
    crossingsBody.push(<div key={`row${i}`} className='crossings-row'>{crossings}</div>)
  }

  return (
    <div id="crossingsTable" className="crossings-body">
      {crossingsBody}
    </div>
  );
}