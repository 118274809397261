import { useState } from 'react';
import './ModalStyle.css'
import { crossingName } from './helperFunctions';

export function ContestModal({ctx, G, moves}) {
  const player = `player${ctx.currentPlayer}`

  const maxAggregates = {
    1: 2,
    2: 2,
    3: 3,
    4: 3,
    5: 4,
    6: 4
  }

  const [aggregates, setAggregates] = useState(G[player].aggregates.map(item => (
    {
      ...item, 
      checked: G[player].aggregates.length === maxAggregates[G[player].weaver]
    }
  )))
  const [maxChecked, setMaxChecked] = useState(false)
  const [disableUsed, setDisableUsed] = useState(true)
  const [aggSubmitted, setAggSubmitted] = useState(false)
  const [chosenDie, setChosenDie] = useState(null)
  const [sorceryInput, setSorceryInput] = useState('')
  const [sunBonus, setSunBonus] = useState(0)
  const [useSun, setUseSun] = useState(false)
  const [canContestSun, setCanContestSun] = useState(false)

  const handleCheck = index => {
    const aggregateClone = [...aggregates];
    aggregateClone[index].checked = !aggregateClone[index].checked
    setAggregates(aggregateClone);

    if (aggregates.filter(i => i.checked).length >= maxAggregates[G[player].weaver]) {
      setMaxChecked(true);
    } else if (maxChecked) {
      setMaxChecked(false);
    }
    if (aggregates.filter(i => !i.used).every(i => i.checked)) {
      setDisableUsed(false);
    } else if (!disableUsed) {
      setDisableUsed(true);
    }
  }

  const aggregateSubmit = () => {
    if (aggregates.filter(i => i.checked).length === 0) {
      setChosenDie(0)
    } else if (aggregates.every(i => i.checked)) {
      moves.aggregateSubmit(aggregates.map(item => (
        {name: item.name, used: false, enhanced: item.enhanced}
      )))
    } else if (!disableUsed) {
      moves.aggregateSubmit(aggregates.map(item => (
        {name: item.name, used: item.checked, enhanced: item.enhanced}
      )))
    } else {
      moves.aggregateSubmit(aggregates.map(item => (
        {name: item.name, used: item.checked ? item.checked : item.used, enhanced: item.enhanced }
      )))
    }
    setAggSubmitted(true);
    const updatedAggregates = aggregates.map(agg => (
      agg.checked ? {...agg, die: Math.floor((Math.random() * 10) + 1)} : agg
    ))
    setAggregates(updatedAggregates)
    const rolledAggregates = updatedAggregates.filter(agg => 'die' in agg)
    const numberOfTens = rolledAggregates.filter(agg => agg.die === 10).length
    setSunBonus(numberOfTens * G.cells[9].length)
    if (rolledAggregates.length > 0 && rolledAggregates.length === numberOfTens && G.crossings[16].outer !== ctx.currentPlayer)
    rolledAggregates.length === numberOfTens && setCanContestSun(true)
  }

  const position = G[player].position

  const layer = G.crossings[position].outer === ctx.currentPlayer ? 'inner' : 'outer'

  const handleFinalSubmit = () => {
    if (sorceryInput > G[player].sorcery) {
      return
    } else {
      moves.progressContest(parseInt(chosenDie), parseInt(sorceryInput || 0), parseInt(useSun ? sunBonus : 0))
    }
  }

  const handlecontestSun = () => {
    if (sorceryInput > G[player].sorcery) {
      return
    } else {
      moves.contestSun(parseInt(chosenDie), parseInt(sorceryInput || 0), parseInt(sunBonus))
    }
  }

  const handleChooseDie = (agg) => {
    if (G[player].sorcery === 0 && sunBonus === 0 && !canContestSun) {
      moves.progressContest(parseInt(agg.enhanced ? agg.die + 1 : agg.die), 0, 0)
    }
    agg.enhanced ? setChosenDie(agg.die + 1) : setChosenDie(agg.die)
  }

  return (
    <div className='modal-container'>
      <div className='contest-modal'>
        <div className='interface-container'>
          <p>Contesting <span style={{fontWeight:'bold'}}>{crossingName[position]} {layer}</span></p>
          <p>Defense value: {G.crossings[position][player].defense}</p>
          <p>Select the dice you wish to contest with.</p>
          <div className='agg-container'>
            {aggregates.map((agg, i) => (
              <label key={i}>
                {agg.name} {agg.enhanced && '(+1)'} {' '}
                <input
                  type='checkbox'
                  value={agg.name}
                  disabled={aggSubmitted || (!agg.checked && ((agg.used && disableUsed) || maxChecked))}
                  checked={agg.checked}
                  onChange={() => handleCheck(i)}
                />
                {agg.die && <span> {' '} <button disabled={chosenDie} onClick={() => handleChooseDie(agg)}>{agg.die} {agg.enhanced ? '+ 1' : null}</button></span>}
              </label>
            ))}
            <button disabled={aggSubmitted} onClick={() => aggregateSubmit()}>Roll</button>
          </div>
          {chosenDie !== null &&
            <div className='agg-container'>
              {sunBonus > 0 && <div><input type='checkbox' checked={useSun} onChange={() => setUseSun(!useSun)}/> Use +{sunBonus} from Invisible Sun? ({G.cells[9].length} cards × {aggregates.filter(agg => agg.die === 10).length} 10's rolled). <div className='centered-text'>If checked, cards will be spent.</div></div>}
              {G[player].sorcery > 0 &&
                <div>
                  <p className='centered-text'>You have {G[player].sorcery} sorcery to spend.</p>
                  <p>{chosenDie} {useSun && `+ ${sunBonus} `}+ <input type="text" value={sorceryInput} onChange={e => setSorceryInput(e.target.value)} maxLength='2'/></p>
                </div>
              }
              <button className='submit-button' onClick={() => handleFinalSubmit()}>Place Card</button>
              {canContestSun && 
                <div>
                  <p>Or you may contest the Invisible Sun. Cards will be spent by default. Defense value: {G.crossings[16][player].defense}</p>
                  <button className='submit-button' onClick={() => handlecontestSun()}>Contest Sun</button>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}