import { generatePlayLogText } from "./helperFunctions"
import './SideboardStyle.css'

export function ExportButton ({ log }) {
  const generateLink = () => {
    const translatedLog = log.map(event => {
      return ('player' in event ?
        `${event.player === '0' ? 'Player Ant ' : 'Player Spider '}${generatePlayLogText(event)}` :
        generatePlayLogText(event)
      )
    })
    const fileData = JSON.stringify(translatedLog, null, '\n')
    const blob = new Blob([fileData], { type: "text/plain" })
    return String(URL.createObjectURL(blob));
  };

  return (
    <a style={{alignSelf:'center'}} href={generateLink()} download='play-log'>
      <button className='export-button skip-button'>Export Log</button>
    </a>
  )
}