import { useRef, useEffect } from 'react';
import { generatePlayLogText, crossingColorOuter, skipButtonStyle, actionButtonStyle } from './helperFunctions';
import { ExportButton } from './ExportButton';
import './SideboardStyle.css';

export function Sideboard({G, ctx, moves, setTooltipProps}) {
  const filteredHistory = G.playHistory.filter(event => event.type !== 'card-set')

  const skipStage = () => moves.skipStage();
  const makeContest = () => moves.makeContest();

  const bottomRef = useRef();

  useEffect(() => {
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
  }, [G.playHistory]);

  let stageButtons;
  if (G.currentStage === 'move') {
    stageButtons = <button className='skip-button skip-move' style={skipButtonStyle(ctx.currentPlayer)} onClick={() => skipStage()}>
      <p>Skip Move ({ctx.currentPlayer === '0' ? G.antMovesRemaining : 1} Remaining)</p>
    </button>
  } else if (G.currentStage === 'contestOption') {
    const crossing = G.crossings[G[`player${ctx.currentPlayer}`].position]
    if (crossing.inner === ctx.currentPlayer && crossing.outer === ctx.currentPlayer) {
      stageButtons = <button className='skip-button' style={skipButtonStyle(ctx.currentPlayer)} onClick={() => skipStage()}>No Contest</button>
    } else {
      stageButtons = (
        <>
          <button className='action-button' style={actionButtonStyle(ctx.currentPlayer)} onClick={() => makeContest()}>Contest</button>
          <button className='skip-button' style={skipButtonStyle(ctx.currentPlayer)} onClick={() => skipStage()}>Skip</button>
        </>
      )
    }
  }

  return (
    <div id="sideBoard" className="sideboard">
      <div className='sideboard-top'>
        <button className='crossings-item crossings-outer invisible-crossing' style={crossingColorOuter({G, id: 16})}
          onMouseEnter={() => setTooltipProps(G.crossings[16][`player${ctx.currentPlayer}`])} onMouseLeave={() => setTooltipProps(null)}>
          <div className='defense-value'><p>{G.crossings[16][`player${ctx.currentPlayer}`].defense}</p></div>
        </button>
        <div className='invisible-sun-cell'>
          {!!G.cells[9] && G.cells[9].map(card => 
            <div key={card.name}>
              <img src={card.img} alt={card.name}  className='invisible-sun-sooth sideboard-sooth'
                onMouseEnter={() => setTooltipProps(card)} onMouseLeave={() => setTooltipProps(null)}/>
            </div>
          )}
        </div>
      </div>
      <div className='sideboard-bottom'>
        <div className='sideboard-bottom-left'>
          <div className='sooth-deck'/>
          {G.drawnCard &&
            <div className='drawn-card'>
              <img src={G.drawnCard.img} alt={G.drawnCard.name} key={G.drawnCard.name} className='sideboard-sooth'
                onMouseEnter={() => setTooltipProps(G.drawnCard)} onMouseLeave={() => setTooltipProps(null)}/>
            </div>
          }
          <div className='stage-buttons'>{stageButtons}</div>
          </div>
        <div className='play-log-container'>
          <div className='play-log'>
            <div className='play-log-buffer'/>
            {filteredHistory.map(event => <p key={event.number}><span className={`player${event.player}log`}>{event.player === '0' ? 'Player Ant ' : 'Player Spider '}</span>{generatePlayLogText(event)}</p>)}
            <div id='bottom-reference' ref={bottomRef}/>
          </div>
          <ExportButton log={G.playHistory}/>
        </div>
      </div>
    </div>
  );
}