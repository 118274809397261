import { useState } from 'react';
import logo from './assets/sortilege-logo.png'
import './SetupStyle.css';

export function Setup ({ moves }) {
  const [page, setPage] = useState('home')
  const [settingsObj, setSettingsObj] = useState(
    {
      sorcery: 0,
      player1: {
        suit: 'Secrets',
        weaver: 1,
        aggregates: ['A','B','C','D','E','F','G'],
        token: 'ant',
        position: '0'
      },
      player2: {
        suit: 'Secrets',
        weaver: 1,
        aggregates: ['A','B','C','D','E','F','G'],
        token: 'spider',
        position: '15'
      },
      simpleMovement: false
    }
  )

  const [first] = useState(Math.floor(Math.random() * 2 + 1))
  const [second] = useState(first === 1 ? 2 : 1)
  const [order] = useState([first,second])

  const sorceryOptions = [];

  for (let i = 0; i <= 20; i++) {
    sorceryOptions.push(<option value={i} key={i}>{i}</option>)
  }

  const quickStart = () => {
    const tokens = ['ant', 'spider']
    const firstToken = tokens[Math.floor(Math.random() * 2)]
    const secondToken = firstToken === 'ant' ? 'spider' : 'ant'
    const positions = [0,15]
    const firstPosition = positions[Math.floor(Math.random() * 2)]
    const secondPosition = firstPosition === 0 ? 15 : 0
    
    moves.startGame(
      {...settingsObj,
        player1: {
          ...settingsObj.player1,
          aggregates: ['Alleyways','Winter'],
          token: firstToken,
          position: firstPosition
        },
        player2: {
          ...settingsObj.player2,
          suit: 'Visions',
          aggregates: ['Freedom','Wind'],
          token: secondToken,
          position: secondPosition
        }
      }, order
    )
  }

  const pageHome = (
    <div className='page-container'>
      <div className='options-container'>
        <img src={logo} height="25%" width="25%" alt="Sortilege logo"/>
        <h1>Spider's Game</h1>
      </div>
      <div className='buttons-div'>
        <button onClick={quickStart}>Quick Start</button>
        <button onClick={() => setPage(0)}>Advanced Mode</button>
      </div>
      <div className='movement-option'>
          Use simple movement rules? 
          <input
            type='checkbox'
            value='movement-rules'
            checked={settingsObj.simpleMovement}
            onChange={() => setSettingsObj({...settingsObj, simpleMovement: !settingsObj.simpleMovement})}
          />
        </div>
    </div>
  )

  const page0 = (
    <div className='page-container'>
      <div className='options-container'>
        <p>Set sorcery amount:</p>
        <select className='options-select' value={settingsObj.sorcery} onChange={e => setSettingsObj({...settingsObj, sorcery: e.target.value})}>
          {sorceryOptions}
        </select>
      </div>
      <div className='buttons-div'>
          <button onClick={() => setPage('home')}>Back</button>
          <button onClick={() => setPage(1)}>Next</button>
      </div>
    </div>
  )

  const page1 = (
    <div className='page-container'>
      <div className='options-container'>
        <p>Player 1 Suit</p>
        <form>
          <label>
            <input type='radio' name='player1suit' value='Secrets' checked={settingsObj.player1.suit === 'Secrets'} onChange={e => setSettingsObj({...settingsObj, player1: {...settingsObj.player1, suit: e.target.value}})}/>
            Secrets 
          </label>
          <label>
            <input type='radio' name='player1suit' value='Visions' checked={settingsObj.player1.suit === 'Visions'} onChange={e => setSettingsObj({...settingsObj, player1: {...settingsObj.player1, suit: e.target.value}})}/>
            Visions 
          </label>
          <label>
            <input type='radio' name='player1suit' value='Mysteries' checked={settingsObj.player1.suit === 'Mysteries'} onChange={e => setSettingsObj({...settingsObj, player1: {...settingsObj.player1, suit: e.target.value}})}/>
            Mysteries 
          </label>
          <label>
            <input type='radio' name='player1suit' value='Notions' checked={settingsObj.player1.suit === 'Notions'} onChange={e => setSettingsObj({...settingsObj, player1: {...settingsObj.player1, suit: e.target.value}})}/>
            Notions
          </label>
        </form>
        <p>Player 2 Suit</p>
        <form>
          <label>
            <input type='radio' name='player2suit' value='Secrets' checked={settingsObj.player2.suit === 'Secrets'} onChange={e => setSettingsObj({...settingsObj, player2: {...settingsObj.player2, suit: e.target.value}})}/>
            Secrets 
          </label>
          <label>
            <input type='radio' name='player2suit' value='Visions' checked={settingsObj.player2.suit === 'Visions'} onChange={e => setSettingsObj({...settingsObj, player2: {...settingsObj.player2, suit: e.target.value}})}/>
            Visions 
          </label>
          <label>
            <input type='radio' name='player2suit' value='Mysteries' checked={settingsObj.player2.suit === 'Mysteries'} onChange={e => setSettingsObj({...settingsObj, player2: {...settingsObj.player2, suit: e.target.value}})}/>
            Mysteries 
          </label>
          <label>
            <input type='radio' name='player2suit' value='Notions' checked={settingsObj.player2.suit === 'Notions'} onChange={e => setSettingsObj({...settingsObj, player2: {...settingsObj.player2, suit: e.target.value}})}/>
            Notions
          </label>
        </form>
      </div>
      <div className='buttons-div'>
        <button onClick={() => setPage(0)}>Back</button>
        <button onClick={() => setPage(2)}>Next</button>
      </div>
    </div>
  )

  const weaverOptions = [];

  for (let i = 1; i <= 6; i++) {
    weaverOptions.push(<option value={i} key={i}>{i}</option>)
  }

  const aggregateDisplay = (playerID, aggregate) => (
    settingsObj[`player${playerID}`].weaver >= aggregate ? {display: 'block'} : {display: 'none'}
  )

  const page2 = (
    <div className='pages-container'>
      <div className='options-container'>
        <p>Player 1, select your weaver degree</p>
        <select className='options-select' value={settingsObj.player1.weaver} onChange={e => setSettingsObj({...settingsObj, player1: {...settingsObj.player1, weaver: e.target.value}})}>
          {weaverOptions}
        </select>
        <p className='options-text' style={aggregateDisplay(1, 0)}>
          1: <input type='text' value={settingsObj.player1.aggregates[0]} onChange={e => setSettingsObj({...settingsObj, player1: {...settingsObj.player1, aggregates: Object.assign([], settingsObj.player1.aggregates, {0: e.target.value}) }})} />  
        </p>
        <p className='options-text' style={aggregateDisplay(1, 1)}>
          2: <input type='text' value={settingsObj.player1.aggregates[1]} onChange={e => setSettingsObj({...settingsObj, player1: {...settingsObj.player1, aggregates: Object.assign([], settingsObj.player1.aggregates, {1: e.target.value}) }})} />
        </p>
        <p className='options-text' style={aggregateDisplay(1, 2)}>
          3: <input type='text' value={settingsObj.player1.aggregates[2]} onChange={e => setSettingsObj({...settingsObj, player1: {...settingsObj.player1, aggregates: Object.assign([], settingsObj.player1.aggregates, {2: e.target.value}) }})} />
        </p>
        <p className='options-text enhanced' style={aggregateDisplay(1, 3)}>
          4: <input type='text' value={settingsObj.player1.aggregates[3]} onChange={e => setSettingsObj({...settingsObj, player1: {...settingsObj.player1, aggregates: Object.assign([], settingsObj.player1.aggregates, {3: e.target.value}) }})} /> +1
        </p>
        <p className='options-text enhanced' style={aggregateDisplay(1, 4)}>
          5: <input type='text' value={settingsObj.player1.aggregates[4]} onChange={e => setSettingsObj({...settingsObj, player1: {...settingsObj.player1, aggregates: Object.assign([], settingsObj.player1.aggregates, {4: e.target.value}) }})} /> +1
        </p>
        <p className='options-text enhanced' style={aggregateDisplay(1, 5)}>
          6: <input type='text'  value={settingsObj.player1.aggregates[5]} onChange={e => setSettingsObj({...settingsObj, player1: {...settingsObj.player1, aggregates: Object.assign([], settingsObj.player1.aggregates, {5: e.target.value}) }})} /> +1
        </p>
        <p className='options-text enhanced' style={aggregateDisplay(1, 6)} >
          7: <input type='text' value={settingsObj.player1.aggregates[6]} onChange={e => setSettingsObj({...settingsObj, player1: {...settingsObj.player1, aggregates: Object.assign([], settingsObj.player1.aggregates, {6: e.target.value}) }})} /> +1
        </p>
      </div>
      <div className='buttons-div'>
        <button onClick={() => setPage(1)}>Back</button>
        <button onClick={() => setPage(3)}>Next</button>
      </div>
    </div>
  )

  const page3 = (
    <div className='pages-container'>
      <div className='options-container'>
        <p>Player 2, select your weaver degree</p>
        <select className='options-select' value={settingsObj.player2.weaver} onChange={e => setSettingsObj({...settingsObj, player2: {...settingsObj.player2, weaver: e.target.value}})}>
          {weaverOptions}
        </select>
        <p className='options-text' style={aggregateDisplay(2, 0)}>
          1: <input type='text' value={settingsObj.player2.aggregates[0]} onChange={e => setSettingsObj({...settingsObj, player2: {...settingsObj.player2, aggregates: Object.assign([], settingsObj.player2.aggregates, {0: e.target.value}) }})} />  
        </p>
        <p className='options-text' style={aggregateDisplay(2, 1)}>
          2: <input type='text' value={settingsObj.player2.aggregates[1]} onChange={e => setSettingsObj({...settingsObj, player2: {...settingsObj.player2, aggregates: Object.assign([], settingsObj.player2.aggregates, {1: e.target.value}) }})} />
        </p>
        <p className='options-text' style={aggregateDisplay(2, 2)}>
          3: <input type='text' value={settingsObj.player2.aggregates[2]} onChange={e => setSettingsObj({...settingsObj, player2: {...settingsObj.player2, aggregates: Object.assign([], settingsObj.player2.aggregates, {2: e.target.value}) }})} />
        </p>
        <p className='options-text enhanced' style={aggregateDisplay(2, 3)}>
          4: <input type='text' value={settingsObj.player2.aggregates[3]} onChange={e => setSettingsObj({...settingsObj, player2: {...settingsObj.player2, aggregates: Object.assign([], settingsObj.player2.aggregates, {3: e.target.value}) }})} /> +1
        </p>
        <p className='options-text enhanced' style={aggregateDisplay(2, 4)}>
          5: <input type='text' value={settingsObj.player2.aggregates[4]} onChange={e => setSettingsObj({...settingsObj, player2: {...settingsObj.player2, aggregates: Object.assign([], settingsObj.player2.aggregates, {4: e.target.value}) }})} /> +1
        </p>
        <p className='options-text enhanced' style={aggregateDisplay(2, 5)}>
          6: <input type='text'  value={settingsObj.player2.aggregates[5]} onChange={e => setSettingsObj({...settingsObj, player2: {...settingsObj.player2, aggregates: Object.assign([], settingsObj.player2.aggregates, {5: e.target.value}) }})} /> +1
        </p>
        <p className='options-text enhanced' style={aggregateDisplay(2, 6)} >
          7: <input type='text' value={settingsObj.player2.aggregates[6]} onChange={e => setSettingsObj({...settingsObj, player2: {...settingsObj.player2, aggregates: Object.assign([], settingsObj.player2.aggregates, {6: e.target.value}) }})} /> +1
        </p>
      </div>
      <div className='buttons-div'>
        <button onClick={() => setPage(2)}>Back</button>
        <button onClick={() => setPage(4)}>Next</button>
      </div>
    </div>
  )

  const setToken = (token) => {
    let otherToken;
    if (token === 'ant') {
      otherToken = 'spider';
    } else {
      otherToken = 'ant';
    }

    if (order[0] === 1) {
      setSettingsObj({...settingsObj, player1: {...settingsObj.player1, token: token}, player2: {...settingsObj.player2, token: otherToken}})
    } else {
      setSettingsObj({...settingsObj, player1: {...settingsObj.player1, token: otherToken}, player2: {...settingsObj.player2, token: token}})
    }
  }

  const page4 = (
    <div className='pages-container'>
      <div className='options-container'>
        <p>Player {order[0]}, choose your token</p>
        <select className='options-select' value={settingsObj[`player${order[0]}`].token} onChange={e => setToken(e.target.value)}>
          <option value='ant'>Ant</option>
          <option value='spider'>Spider</option>
        </select>
      </div>
      <div className='buttons-div'>
        <button onClick={() => setPage(3)}>Back</button>
        <button onClick={() => setPage(5)}>Next</button>
      </div>
    </div>
  )

  const setStartingCorner = (pos) => {
    let otherPos;
    if (pos === '0') {
      otherPos = '15'
    } else {
      otherPos = '0'
    }

    if (order[1] === 1) {
      setSettingsObj({...settingsObj, player1: {...settingsObj.player1, position: pos}, player2: {...settingsObj.player2, position: otherPos}})
    } else {
      setSettingsObj({...settingsObj, player1: {...settingsObj.player1, position: otherPos}, player2: {...settingsObj.player2, position: pos}})
    }
  }

  const page5 = (
    <div className='pages-container'>
      <div className='options-container'>
        <p>Player {order[1]}, choose your starting corner</p>
        <select className='options-select' value={settingsObj[`player${order[1]}`].position} onChange={e => setStartingCorner(e.target.value)}>
          <option value='0'>upper-left</option>
          <option value='15'>bottom-right</option>
        </select>
      </div>
      <div className='buttons-div'>
        <button onClick={() => setPage(4)}>Back</button>
        <button onClick={() => moves.startGame(settingsObj, order)}>Start</button>
      </div>
    </div>
  )

  const pages = {
    home: pageHome,
    0: page0,
    1: page1,
    2: page2,
    3: page3,
    4: page4,
    5: page5
  }

  return (
    <div className='setup-container'>
      <div className='main'>
        {pages[page]}
      </div>
    </div>
  )
}