import React, { useEffect, useState } from 'react';
import { Cells } from './Cells';
import { Crossings } from './Crossings';
import { Sideboard } from './Sideboard';
import { Tooltip } from './Tooltip';
import { ContestModal } from './ContestModal';
import './BoardStyle.css';
import { GameOverModal } from './GameOverModal';

export function SpidersGameBoard({ ctx, G, moves }) {
  const [tooltipProps, setTooltipProps] = useState(null)
  const [crossingDisplay, setCrossingDisplay] = useState(G.crossings)
  const [cellDisplay, setCellDisplay] = useState(G.cells)

  useEffect(() => {
    const tooltip = document.getElementById('tooltip')
    document.addEventListener("mousemove", (e) => {
      tooltip.style.top = (e.clientY + 20) + 'px';
      tooltip.style.left = (e.clientX + 20) + 'px';
    })
  },[])

  useEffect(() => {
    setCrossingDisplay(G.crossings)
    setCellDisplay(G.cells)
  }, [G.crossings, G.cells])

  return (
    <div id="board" className="board">
      <Cells G={G} moves={moves} setTooltipProps={setTooltipProps} cellDisplay={cellDisplay} setCrossingDisplay={setCrossingDisplay} setCellDisplay={setCellDisplay}/>
      <Crossings ctx={ctx} G={G} moves={moves} setTooltipProps={setTooltipProps} crossingDisplay={crossingDisplay}/>
      <Sideboard G={G} ctx={ctx} moves={moves} setTooltipProps={setTooltipProps}/>
      <Tooltip props={tooltipProps}/>
      {G.currentStage === 'contest' && <ContestModal ctx={ctx} G={G} moves={moves}/>}
      {'gameover' in ctx && <GameOverModal ctx={ctx} G={G}/>}
    </div>
  );
}