import './ModalStyle.css'
import { ExportButton } from './ExportButton'

export function GameOverModal ({G, ctx}) {
  return (
    <div className='modal-container'>
      <div className='contest-modal'>
        <div className='interface-container'>
          <p>Game over! Player {ctx.gameover.winner === '0' ? 'Ant' : 'Spider'} wins.</p>
          <ExportButton log={G.playHistory}/>
        </div>
      </div>
    </div>
  )
}