import { useState } from "react";
import './LoginStyle.css';

const LogIn = ({setLogIn}) => {
  const [input, setInput] = useState('')

  return (
    <form className='login-background'>
      <input className='login-input' type="password" value={input} onChange={e => setInput(e.target.value)}/>
      <button className='login-button' type='submit' onClick={() => setLogIn(input)}>Enter</button>
    </form>
  )
}

export default LogIn;