import { cellBorderStyle, cardPlacementCells, cardPlacementCrossings, calculateDefenseValue } from './helperFunctions';
import { ImArrowUp, ImArrowRight, ImArrowDown, ImArrowLeft } from "react-icons/im";

export function Cells({G, moves, setTooltipProps, cellDisplay, setCrossingDisplay, setCellDisplay}) {
  const onClick = (id) => G.drawnCard && moves.placeCard(id);

  const cardButtonNum = {
    0: 3,
    1: 5,
    2: 7,
    3: 4,
    4: 6,
    5: 8
  }

  const handleMouseEnter = (id) => {
    if (!G.drawnCard) {
      return
    }

    const cardToPlace = G.drawnCard

    const copyOfG = {
      player0: G.player0,
      player1: G.player1,
      cells: [...G.cells],
      crossings: [...G.crossings]
    }

    const affectedCells = cardPlacementCells[id];
    copyOfG.cells[affectedCells[0]] = copyOfG.cells[affectedCells[1]];
    copyOfG.cells[affectedCells[1]] = copyOfG.cells[affectedCells[2]];
    copyOfG.cells[affectedCells[2]] = cardToPlace;
    const crossingsToUpdate = cardPlacementCrossings[id];

    crossingsToUpdate.forEach(i => {
      const newCrossing = calculateDefenseValue(copyOfG, i);
      copyOfG.crossings[i] = newCrossing;
    })

    setCrossingDisplay(copyOfG.crossings)
    setCellDisplay(copyOfG.cells)
  }

  const handleMouseLeave = () => {
    setCrossingDisplay(G.crossings)
    setCellDisplay(G.cells)
  }

  let cardsActive;
  if (G.drawnCard) {cardsActive = {opacity: 1, cursor: 'pointer'}}

  let cellsBody = [];
  for (let i = 0; i < 3; i++) {
    let cells = [];
    for (let j = 0; j < 3; j++) {
      const id = 3 * i + j;
      cells.push(
        <div key={id} className='cells-item' style={cellBorderStyle(id)}>
          <img src={cellDisplay[id].img} alt={cellDisplay[id].name} className='soothcard'
            onMouseEnter={() => setTooltipProps(cellDisplay[id])} onMouseLeave={() => setTooltipProps(null)}/>
        </div>
      )
    }
    cellsBody.push(
      <div key={`row${i}`} className='cells-row'>
        <button key={cardButtonNum[i]} 
          id={cardButtonNum[i]} 
          onClick={() => onClick(cardButtonNum[i])} 
          onMouseEnter={() => handleMouseEnter(cardButtonNum[i])} 
          onMouseLeave={() => handleMouseLeave(cardButtonNum[i])} 
          className='card-buttons card-buttons-left' 
          style={cardsActive}
        >
          <ImArrowRight />
        </button>
          {cells}
        <button 
          key={cardButtonNum[i+3]} 
          id={cardButtonNum[i+3]} 
          onClick={() => onClick(cardButtonNum[i+3])} 
          onMouseEnter={() => handleMouseEnter(cardButtonNum[i+3])} 
          onMouseLeave={() => handleMouseLeave(cardButtonNum[i+3])} 
          className='card-buttons card-buttons-right' 
          style={cardsActive}
        >
          <ImArrowLeft />
        </button>
      </div>
    )
  }

  let topButtons = [];
  for (let i = 0; i < 3; i++) {
    topButtons.push(
      <button 
        key={i} 
        id={i} 
        onClick={() => onClick(i)} 
        onMouseEnter={() => handleMouseEnter(i)} 
        onMouseLeave={() => handleMouseLeave(i)} 
        className='card-buttons card-buttons-top' 
        style={cardsActive}
      >
        <ImArrowDown />
      </button>
    )
  }

  let bottomButtons = []
  for (let i = 9; i < 12; i++) {
    bottomButtons.push(
      <button 
        key={i} 
        id={i} 
        onClick={() => onClick(i)} 
        onMouseEnter={() => handleMouseEnter(i)} 
        onMouseLeave={() => handleMouseLeave(i)} 
        className='card-buttons card-buttons-bottom' 
        style={cardsActive}
      >
        <ImArrowUp />
      </button>
    )
  }

  return (
  <div id="cellsTable" className="cells-body">
    <div className='card-buttons-div'>
      {topButtons}
    </div>
    {cellsBody}
    <div className='card-buttons-div'>
      {bottomButtons}
    </div>
  </div>
  );
}